/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "~jstree/dist/themes/default/style.min.css";
/* Add application styles & imports to this file! */
/* You can add global styles to this file, and also import other style files */

/* ALL LOADERS */

/* General -------------------------------------------------------------------------------------- */
* {
  padding: 0;
  border: 0;
  outline: 0;
  margin: 0;
}

h1,
h2 {
  font-weight: normal;
  font-size: 1rem;
}

a {
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

ul {
  list-style-type: none;
}

table {
  border-collapse: collapse;
}

table th,
table td {
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}

label {
  cursor: pointer;
  
}

input,
button,
select {
  background-color: transparent;
  font-family: 'Oxygen', Arial, Helvetica, sans-serif;
  font-size: 1rem; 
}

button,
select {
  cursor: pointer;
  
}

button {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: white;
}

select {
  -webkit-appearance: none;
}

input[type=text],
input[type=number],
input[type=email],
input[type=url],
input[type=password],
input[type=date],
input[type=search],
input[type=tel] {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

/* Page container ------------------------------------------------------------------------------- */
#page_container {
  width: 980px;
  padding: 40px 5px 55px 5px;
  margin: 0 auto 0 auto;
}

/* Header --------------------------------------------------------------------------------------- */
h1 {
  font-weight: 700;
  font-size: 2.2rem; 
}

button.button {
  height: 35px;
  display: inline-block;
  background-color: #999;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  padding: 0 15px 0 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0 0 25px 0;
}

button.button:hover,
button.button:active {
  background-color: #333;
}

/* Datatable ------------------------------------------------------------------------------------ */
.dataTables_wrapper {
  position: relative;
  padding: 50px 0 50px 0;
}

.dataTables_length {
  width: auto;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0; 
}

.dataTables_length label {
  line-height: 30px;
  margin: 0;
}

.dataTables_length select {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  color: #666;
  padding: 0 50px 0 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0;
}

.dataTables_length:after {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #999;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 30px;
  text-align: center;
  color: #fff;
  content: '\f107';
  pointer-events: none;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dataTables_length select::-ms-expand {
  display: none;
}

.dataTables_filter {
  position: absolute;
  top: 0;
  right: 0;
}

.dataTables_filter label {
  line-height: 30px;
}

.dataTables_filter input {
  width: 200px;
  height: 30px;
  display: inline-block;
  background-color: #fff;
  line-height: 30px;
  color: #666;
  padding: 0 0 0 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0 0 0 10px;
}

.dataTables_filter input:focus {
  background-color: #ffd;
}

.dataTables_paginate {
  position: absolute;
  bottom: 0;
  left: 0;
}

.dataTables_paginate a {
  background-color: #999;
  font-weight: normal;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0 10px 0 0;
}

.dataTables_paginate a.current,
.dataTables_paginate a:hover,
.dataTables_paginate a:active,
.dataTables_paginate a:focus {
  background-color: #333;
}

.dataTables_paginate a.previous,
.dataTables_paginate a.next {
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
}

.dataTables_paginate a.previous:before {
  content: '\f104';
}

.dataTables_paginate a.next:before {
  content: '\f105';
}

.dataTables_info {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 30px;
}

table.datatable {
  width: 100% !important;
  line-height: 1.4rem;
}

table.datatable th,
table.datatable td {
  background-color: #fff;
  padding: 5px 10px 5px 10px;
  border: 1px solid #ccc;
}

table.datatable thead th {
  background-color: #999;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  padding-top: 7px;
  padding-bottom: 8px;
}

table.datatable thead th.sorting,
table.datatable thead th.sorting_desc,
table.datatable thead th.sorting_asc {
  cursor: pointer;
}

table.datatable thead th.sorting:active,
table.datatable thead th.sorting_desc:active,
table.datatable thead th.sorting_asc:active {
  background-color: #333;
}

table.datatable tbody tr:nth-child(even) td {
  background-color: #eee;
}

table.datatable tbody tr:hover th,
table.datatable tbody tr:hover td {
  background-color: #ffd;
}

table.datatable tbody tr:hover td.dataTables_empty {
  background-color: #fff;
}

table.datatable tbody td.company_name {
  width: 100%;
}

table.datatable tbody td.integer {
  text-align: right;
  white-space: nowrap;
}

table.datatable tbody td.nowrap {
  white-space: nowrap;
}

table.datatable tbody td.functions .function_buttons {
  width: 70px;
  height: 30px;
  margin: 0 auto 0 auto;
}

table.datatable tbody td.functions .function_buttons li {
  float: left;
  padding: 0 10px 0 0;
}

table.datatable tbody td.functions .function_buttons li.function_delete {
  padding: 0;
}

table.datatable tbody td.functions .function_buttons a {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #999;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 29px;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

table.datatable tbody td.functions .function_buttons .function_edit a:before {
  font-size: 1.1rem;
  content: "\f040";
}

table.datatable tbody td.functions .function_buttons .function_delete a:before {
  font-size: 1.2rem;
  line-height: 30px;
  content: "\f1f8";
}

table.datatable tbody td.functions .function_buttons a:hover,
table.datatable tbody td.functions .function_buttons a:active,
table.datatable tbody td.functions .function_buttons a:focus {
  background-color: #333;
}

table.datatable tbody td.functions .function_buttons span {
  display: none;
}

/* Lightbox ------------------------------------------------------------------------------------- */
.lightbox_bg {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.lightbox_container {
  display: none;
  width: 80%;
  height: 90%;
  position: fixed;
  top: 5%;
  left: 10%;
  z-index: 200;
  background-color: #fff;
  color: #666;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 50px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.lightbox_close {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 45px;
  right: 45px;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #f70;
  cursor: pointer;
  border: 2px solid #f70;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
}

.lightbox_close:before {
  content: '\f00d';
}

.lightbox_close:hover {
  color: #333;
  border-color: #333;
}

.lightbox_content {
  width: 642px;
  padding: 0 50px 0 50px;
}

.lightbox_content h2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  color: #f70;
  margin: 0 0 25px 0;
}

.lightbox_content .input_container {
  width: 600px;
  margin: 0 0 10px 0;
}

.lightbox_content .input_container:after {
  clear: both;
  height: 0;
  display: block;
  font-size: 0;
  line-height: 0;
  content: ' ';
}

.lightbox_content .input_container label {
  width: 200px;
  float: left;
  font-size: 1rem;
  line-height: 32px;
}

.lightbox_content .input_container label span.required {
  font-weight: bold;
  color: #f70;
}

.lightbox_content .input_container .field_container {
  width: 400px;
  float: right;
  position: relative;
}

.lightbox_content .input_container .field_container label.error {
  width: 400px;
  display: block;
  background-color: #fff1e6;
  line-height: 1.4rem;
  color: #333;
  padding: 5px 0 6px 10px;
  border: 1px solid #f70;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0 0 5px 0;
}

.lightbox_content .input_container .field_container label.error.valid {
  display: none !important;
}

.lightbox_content .input_container .field_container input {
  width: 400px;
  height: 32px;
  background-color: #f9f9f9;
  line-height: 30px;
  color: #666;
  padding: 0 0 0 10px;
  border: 1px solid #ccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.lightbox_content .input_container .field_container input:focus {
  background-color: #ffd;
  color: #000;
}

.lightbox_content .input_container .field_container.error:after,
.lightbox_content .input_container .field_container.valid:after {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: -42px;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.lightbox_content .input_container .field_container.error:after {
  content: '\f00d';
  color: #c00;
}

.lightbox_content .input_container .field_container.valid:after {
  content: '\f00c';
  color: #090;
}

.lightbox_content .button_container {
  width: 600px;
  height: 35px;
  text-align: right;
  padding: 15px 0 50px 0;
}

.lightbox_content .button_container button {
  height: 35px;
  display: inline-block;
  background-color: #999;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  padding: 0 15px 0 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.lightbox_content .button_container button:hover {
  background-color: #333;
  color: #fff;
}

/* Message / noscript --------------------------------------------------------------------------- */
#message_container,
#noscript_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  text-align: center;
  color: #fff;
}

#message_container {
  display: none;
}

#message,
#noscript {
  width: 980px;
  line-height: 20px;
  padding: 10px 5px 10px 6px;
  margin: 0 auto 0 auto;
}

#message p,
#noscript p {
  display: inline-block;
  position: relative;
  padding: 0 0 0 28px;
}

#message p:before,
#noscript p:before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f70;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#message.success p:before,
#noscript.success p:before {
  content: '\f00c';
}

#message.error p:before,
#noscript.error p:before {
  content: '\f00d';
}

/* Loading message ------------------------------------------------------------------------------ */
#loading_container {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
}

#loading_container2 {
  width: 100%;
  height: 100%;
  display: table;
}

#loading_container3 {
  display: table-cell;
  vertical-align: middle;
}

#loading_container4 {
  width: 350px;
  height: 250px;
  position: relative;
  background-color: #fff;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #666;
  padding: 165px 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0 auto 0 auto;
}

#loading_container4:before {
  width: 100%;
  position: absolute;
  top: 80px;
  left: 0;
  font-family: 'FontAwesome', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 4rem;
  line-height: 4rem;
  text-align: center;
  color: #f70;
  content: '\f013';
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 1 */

.loader-1:before,
.loader-1:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #3498db;
}

.loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.loader-1:after {
  border: 10px solid #ccc;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* LOADER 2 */

.loader-2 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
}

.loader-2 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.loader-2 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.loader-2 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

/* LOADER 3 */

.loader-3:before,
.loader-3:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  background-color: #3498db;
  animation: squaremove 1s ease-in-out infinite;
}

.loader-3:after {
  bottom: 0;
  animation-delay: 0.5s;
}

@keyframes squaremove {

  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    -ms-transform: translate(0, 0) rotate(0);
    -o-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }

  25% {
    -webkit-transform: translate(40px, 40px) rotate(45deg);
    -ms-transform: translate(40px, 40px) rotate(45deg);
    -o-transform: translate(40px, 40px) rotate(45deg);
    transform: translate(40px, 40px) rotate(45deg);
  }

  50% {
    -webkit-transform: translate(0px, 80px) rotate(0deg);
    -ms-transform: translate(0px, 80px) rotate(0deg);
    -o-transform: translate(0px, 80px) rotate(0deg);
    transform: translate(0px, 80px) rotate(0deg);
  }

  75% {
    -webkit-transform: translate(-40px, 40px) rotate(45deg);
    -ms-transform: translate(-40px, 40px) rotate(45deg);
    -o-transform: translate(-40px, 40px) rotate(45deg);
    transform: translate(-40px, 40px) rotate(45deg);
  }
}

/* LOADER 4 */

.loader-4 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
  opacity: 0;
}

.loader-4 span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

.loader-4 span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.loader-4 span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {

  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

/* LOADER 5 */

.loader-5 span {
  display: block;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  width: 20px;
  height: 20px;
  background-color: #3498db;
}

.loader-5 span:nth-child(2) {
  animation: moveanimation1 1s ease-in-out infinite;
}

.loader-5 span:nth-child(3) {
  animation: moveanimation2 1s ease-in-out infinite;
}

.loader-5 span:nth-child(4) {
  animation: moveanimation3 1s ease-in-out infinite;
}

@keyframes moveanimation1 {

  0%,
  100% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }

  75% {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes moveanimation2 {

  0%,
  100% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  75% {
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@keyframes moveanimation3 {

  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  75% {
    -webkit-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
    transform: translate(30px, 30px);
  }
}

/* LOADER 6 */

.loader-6 {
  top: 40px;
  left: -2.5px;
}

.loader-6 span {
  display: inline-block;
  width: 5px;
  height: 20px;
  background-color: #3498db;
}

.loader-6 span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

.loader-6 span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

.loader-6 span:nth-child(3) {
  animation: grow 1s ease-in-out 0.30s infinite;
}

.loader-6 span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {

  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

/* LOADER 7 */

.loader-7 {
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
}

.loader-7:before {
  content: "";
  position: absolute;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: #3498db;
  animation: flip 1s infinite;
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

/* LOADER 8 */

.loader-8:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 10px);
  left: 0px;
  background-color: #3498db;
  animation: rotatemove 1s infinite;
}

@keyframes rotatemove {
  0% {
    -webkit-transform: scale(1) translateX(0px);
    -ms-transform: scale(1) translateX(0px);
    -o-transform: scale(1) translateX(0px);
    transform: scale(1) translateX(0px);
  }

  100% {
    -webkit-transform: scale(2) translateX(45px);
    -ms-transform: scale(2) translateX(45px);
    -o-transform: scale(2) translateX(45px);
    transform: scale(2) translateX(45px);
  }
}